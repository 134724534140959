import { useRef } from 'react'
import { Box, Flex, Card } from '../primitives'
import GlobalSearch from './GlobalSearch'
import { useRouter } from 'next/router'
import { useHotkeys } from 'react-hotkeys-hook'
import Link from 'next/link'
import Image from 'next/image'
import { ConnectWalletButton } from 'components/ConnectWalletButton'
import NavItem from './NavItem'
import ThemeSwitcher from './ThemeSwitcher'
import HamburgerMenu from './HamburgerMenu'
import MobileSearch from './MobileSearch'
import { useTheme } from 'next-themes'
import { useMediaQuery } from 'react-responsive'
import { useMarketplaceChain, useMounted } from '../../hooks'
import { useAccount } from 'wagmi'
import CartButton from './CartButton'
import { AccountSidebar } from 'components/navbar/AccountSidebar'

import * as HoverCard from '@radix-ui/react-hover-card'
import ChainToggle from 'components/common/ChainToggle'
import { v4 as uuidv4 } from 'uuid'
import {
  isChildrenPageActive,
  isParentPageActive,
} from 'utils/daynamicNavigation'

export const NAVBAR_HEIGHT = 81
export const NAVBAR_HEIGHT_MOBILE = 77

const Navbar = () => {
  const { theme } = useTheme()
  const { isConnected } = useAccount()
  const isMobile = useMediaQuery({ query: '(max-width: 960px' })
  const isMounted = useMounted()
  const { routePrefix } = useMarketplaceChain()
  const { address } = useAccount()

  let searchRef = useRef<HTMLInputElement>(null)

  const router = useRouter()
  useHotkeys('meta+k', (e) => {
    e.preventDefault()
    if (searchRef?.current) {
      searchRef?.current?.focus()
    }
  })

  const resource = {
    id: 4,
    name: 'Resources',
    pages: [
      {
        id: uuidv4(),
        name: 'DiJT Incubator',
        path: '/incubator',
      },
      {
        id: uuidv4(),
        name: 'DiJT Token (Comming Soon)',
        path: '/',
      },
      {
        id: uuidv4(),
        name: 'Developers (Comming Soon)',
        path: '/',
      },
    ],
  }
  if (!isMounted) {
    return null
  }

  return isMobile ? (
    <Flex
      className="z-[100] h-[77px] px-4 w-full border-b-2 border-light-base dark:border-jacarta-900 bg-jacarta-100 dark:bg-jacarta-800 fixed top-0 left-0 right-0"
      align="center"
      justify="between"
    >
      <Box className="flex-1">
        <Flex align="center">
          <Link href={`/${routePrefix}`}>
            <Box className="w-9 cursor-pointer">
              <Image src="/dijt.png" width={34} height={39} alt="DiJT" />
            </Box>
          </Link>
        </Flex>
      </Box>
      <Flex align="center" className="gap-1">
        <ChainToggle />
        <MobileSearch key={`${router.asPath}-search`} />
        <CartButton />
        <HamburgerMenu key={`${router.asPath}-hamburger`} />
      </Flex>
    </Flex>
  ) : (
    <Flex
      className="h-[81px] bg-jacarta-100 dark:bg-jacarta-800 px-5 w-full border-b-2 border-light-base dark:border-jacarta-900 z-50 bg-gray-200 fixed top-0 left-0 right-0"
      align="center"
      justify="between"
    >
      <Box className="flex-1">
        <Flex align="center">
          <Link href={`/${routePrefix}`}>
            <Box className="w-28 cursor-pointer">
              {theme == 'dark' ? (
                <Image
                  src="/dijt_light.png"
                  width={112}
                  height={36}
                  alt="DiJT"
                />
              ) : (
                <Image
                  src="/dijt_dark.png"
                  width={112}
                  height={36}
                  alt="DiJT"
                />
              )}
            </Box>
          </Link>
          <Box className="flex-1 px-5 max-w-[460px] mx-auto">
            <GlobalSearch
              ref={searchRef}
              placeholder="Search collections and addresses"
              containerCss={{ width: '100%' }}
              key={router.asPath}
            />
          </Box>
        </Flex>
      </Box>

      <Flex className="gap-3" justify="end" align="center">
        <ul className="flex flex-col lg:flex-row">
          {/* resource */}
          <li className="js-nav-dropdown group relative">
            <button className="dropdown-toggle text-jacarta-700 font-display hover:text-accent focus:text-accent dark:hover:text-accent dark:focus:text-accent flex items-center justify-between py-3.5 text-base dark:text-white lg:px-5 w-full">
              <NavItem active={router.pathname == '/'}>Resources</NavItem>
              <i className="lg:hidden">
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  viewBox="0 0 24 24"
                  width={24}
                  height={24}
                  className="h-4 w-4 dark:fill-white"
                >
                  <path fill="none" d="M0 0h24v24H0z" />
                  <path d="M12 13.172l4.95-4.95 1.414 1.414L12 16 5.636 9.636 7.05 8.222z" />
                </svg>
              </i>
            </button>
            <ul
              className="dropdown-menu dark:bg-jacarta-800 left-0 top-[85%] z-10 hidden min-w-[200px] gap-x-4 whitespace-nowrap rounded-xl bg-white transition-all will-change-transform group-hover:visible group-hover:opacity-100 lg:invisible lg:absolute lg:grid lg:translate-y-4 lg:py-4 lg:px-2 lg:opacity-0 lg:shadow-2xl lg:group-hover:translate-y-2 relative"
              aria-labelledby="navDropdown-4"
            >
              {resource?.pages?.map?.((page) => (
                <li key={page.id}>
                  <Link
                    href={page?.path}
                    className="dark:hover:bg-jacarta-600 hover:text-accent focus:text-accent hover:bg-jacarta-50 flex items-center rounded-xl px-5 py-2 transition-colors"
                  >
                    <NavItem active={router.pathname == page.path}>
                      {page?.name}
                    </NavItem>
                  </Link>
                  {/*<Link
                        href={page?.path}
                        className="dark:hover:bg-jacarta-600 hover:text-accent focus:text-accent hover:bg-jacarta-50 flex items-center rounded-xl px-5 py-2 transition-colors"
                        >

                        <span
                          className={`font-display ${
                            isChildrenPageActive(page.path, router.asPath)
                              ? "text-accent dark:text-accent"
                              : "text-jacarta-700"
                          } text-sm dark:text-white`}
                        >
                          {page?.name}
                        </span>

                        </Link>*/}
                </li>
              ))}
            </ul>
          </li>
        </ul>
        <Flex align="center" className="gap-5 mr-5">
          <Link href="/dragons">
            <NavItem active={router.pathname == '/dragons'}>Dragons</NavItem>
          </Link>
          <Link href="/blog">
            <NavItem active={router.pathname == '/blog'}>Blogs</NavItem>
          </Link>
          <Link href="https://app.mtopswap.com" target="_blank" rel="noopener noreferrer">
              <NavItem>Swap</NavItem>
          </Link>
        </Flex>
        <ChainToggle />
        <ThemeSwitcher />
        <CartButton />
        {isConnected ? (
          <AccountSidebar />
        ) : (
          <Box className="max-w-[185px]">
            <ConnectWalletButton />
          </Box>
        )}
      </Flex>
    </Flex>
  )
}

export default Navbar
