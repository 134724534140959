import { faShoppingCart } from '@fortawesome/free-solid-svg-icons'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { useConnectModal } from '@rainbow-me/rainbowkit'
import { CartPopover, useCart } from '@reservoir0x/reservoir-kit-ui'
import { Flex, Button, Text } from 'components/primitives'

const CartButton = () => {
  const { data: cartItems } = useCart((cart) => cart.items)
  const { openConnectModal } = useConnectModal()

  return (
    <CartPopover
      onConnectWallet={() => {
        openConnectModal?.()
      }}
      trigger={
        <Button type="button" size="small" className='hover:bg-primary text-black dark:text-white w-[44] h-[44px] justify-center relative'
        >
          <FontAwesomeIcon icon={faShoppingCart} width="16" height="16" />
          {cartItems.length > 0 && (
            <Flex
            className="flex items-center justify-center rounded-full w-5 h-5 bg-primary absolute top-[-8px] right-[-6px]"
          >
              <Text style="subtitle3" css={{ color: 'white' }}>
                {cartItems.length}
              </Text>
            </Flex>
          )}
        </Button>
      }
    />
  )
}

export default CartButton
