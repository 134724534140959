import { Text } from 'components/primitives'
import { ComponentPropsWithoutRef, forwardRef, ReactNode } from 'react'

type NavItemProps = {
  children: ReactNode;
  active?:boolean;
}

const NavItem = forwardRef<
  HTMLParagraphElement,
  ComponentPropsWithoutRef<typeof Text> & NavItemProps
>(({ children,active, ...props }, forwardedRef) => (
  <Text
    {...props}
    ref={forwardedRef}
    className={`${active? 'text-accent': 'text-black dark:text-white'} hover:text-accent-light`}
    css={{
      cursor: 'pointer',
    }}
    as="p"
    style="subtitle1"
  >
    {children}
  </Text>
))

export default NavItem
