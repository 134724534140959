import { Anchor, Box, Button, Flex, Text } from 'components/primitives'
import { Avatar } from 'components/primitives/Avatar'
import * as RadixDialog from '@radix-ui/react-dialog'
import {
  faBars,
  faXmark,
  faRightFromBracket,
} from '@fortawesome/free-solid-svg-icons'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import {  faXTwitter } from '@fortawesome/free-brands-svg-icons'
import Link from 'next/link'
import Image from 'next/image'
import { useAccount, useDisconnect } from 'wagmi'
import { ConnectWalletButton } from 'components/ConnectWalletButton'
import Jazzicon, { jsNumberForAddress } from 'react-jazzicon'
import { FullscreenModal } from 'components/common/FullscreenModal'
import { useENSResolver, useMarketplaceChain } from 'hooks'
import ThemeSwitcher from 'components/navbar/ThemeSwitcher'
import Wallet from 'components/navbar/Wallet'

const HamburgerMenu = () => {
  const { address, isConnected } = useAccount()
  const {
    avatar: ensAvatar,
    shortAddress,
    shortName: shortEnsName,
  } = useENSResolver(address)
  const { disconnect } = useDisconnect()
  const { routePrefix } = useMarketplaceChain()

  const trigger = (
    <Button
      css={{ justifyContent: 'center', width: '44px', height: '44px' }}
      type="button"
      size="small"
    >
      <FontAwesomeIcon icon={faBars} width={16} height={16} />
    </Button>
  )

  return (
    <FullscreenModal trigger={trigger}>
      {' '}
      <Flex
        css={{
          flexDirection: 'column',
          justifyContent: 'space-between',
        }}
        className="bg-light-base dark:bg-jacarta-800"
      >
        <Flex
          className="border-b border-primary"
          css={{
            py: '$4',
            px: '$4',
            width: '100%',
          }}
          align="center"
          justify="between"
        >
          <Link href={`/${routePrefix}`}>
            <Box css={{ width: 46, cursor: 'pointer' }}>
              <Image src="/dijt.png" width={36} height={36} alt="DiJT" />
            </Box>
          </Link>
          <Flex className="grow flex-row-reverse mr-2">
            <ThemeSwitcher />
          </Flex>
          <RadixDialog.Close>
            <Flex
              className=" bg-primary"
              css={{
                justifyContent: 'center',
                width: '44px',
                height: '44px',
                alignItems: 'center',
                borderRadius: 8,
              }}
            >
              <FontAwesomeIcon icon={faXmark} width={16} height={16} />
            </Flex>
          </RadixDialog.Close>
        </Flex>
        {isConnected ? (
          <Flex
            css={{
              flexDirection: 'column',
              justifyContent: 'flex-start',
              height: '100%',
              py: '$5',
              px: '$4',
            }}
          >
            <Link href={`/portfolio/${address || ''}`} legacyBehavior>
              <Flex
                css={{
                  justifyContent: 'space-between',
                  alignItems: 'center',
                  cursor: 'pointer',
                  pb: '$4',
                }}
              >
                <Flex css={{ alignItems: 'center' }}>
                  {ensAvatar ? (
                    <Avatar size="medium" src={ensAvatar} />
                  ) : (
                    <Jazzicon
                      diameter={36}
                      seed={jsNumberForAddress(address as string)}
                    />
                  )}
                  <Text style="subtitle1" css={{ ml: '$2' }}>
                    {shortEnsName ? shortEnsName : shortAddress}
                  </Text>
                </Flex>
              </Flex>
            </Link>
            {/*<Link href="/collection-rankings" legacyBehavior>
              <Text className='border-b border-primary'
                style="subtitle1"
                css={{
                  cursor: 'pointer',
                  pb: '$4',
                  pt: '24px',
                }}
              >
                Explore
              </Text>
              </Link>
            <Link href="/ethereum/collection-rankings" legacyBehavior>
              <Text
                className="border-b border-primary text-center"
                style="subtitle1"
                css={{
                  cursor: 'pointer',
                  pb: '$4',
                  pt: '24px',
                }}
              >
                Trending
              </Text>
            </Link>*/}
            <Link href="/portfolio" legacyBehavior>
              <RadixDialog.Close>
                <Flex
                  className="border-b border-primary"
                  direction="column"
                  css={{
                    cursor: 'pointer',
                    pb: '$4',
                    pt: '12px',
                    gap: '$1',
                  }}
                >
                  <Text style="subtitle1">Portfolio</Text>
                  <Text style="body3" color="subtle">
                    Manage your items, collections, listings and offers
                  </Text>
                </Flex>
              </RadixDialog.Close>
            </Link>
            <Link href="/blog" legacyBehavior>
              <RadixDialog.Close>
                <Flex
                  className="border-b border-primary"
                  direction="column"
                  css={{
                    cursor: 'pointer',
                    pb: '$4',
                    pt: '12px',
                    gap: '$1',
                  }}
                >
                  <Text style="subtitle1">Blogs</Text>
                  <Text style="body3" color="subtle"></Text>
                </Flex>
              </RadixDialog.Close>
            </Link>
            <Link href="/dragons" legacyBehavior>
              <RadixDialog.Close>
                <Flex
                  className="border-b border-primary"
                  direction="column"
                  css={{
                    cursor: 'pointer',
                    pb: '$4',
                    pt: '12px',
                    gap: '$1',
                  }}
                >
                  <Text style="subtitle1">Dragons</Text>
                  <Text style="body3" color="subtle">
                    Mint your DiJT Dragons
                  </Text>
                </Flex>
              </RadixDialog.Close>
            </Link>
            <Link href="https://app.mtopswap.com" target="_blank" rel="noopener noreferrer" legacyBehavior>
              <RadixDialog.Close>
                <Flex
                  className="border-b border-primary"
                  direction="column"
                  css={{
                    cursor: 'pointer',
                    pb: '$4',
                    pt: '12px',
                    gap: '$1',
                  }}
                >
                  <Text style="subtitle1">Swap</Text>
                  <Text style="body3" color="subtle">
                    Swap your tokens effortlessly using MtopSwap
                  </Text>
                </Flex>
              </RadixDialog.Close>
            </Link>
            <Wallet />
            <RadixDialog.Close>
              <Flex
                css={{
                  justifyContent: 'space-between',
                  cursor: 'pointer',
                  alignItems: 'center',
                }}
                onClick={() => disconnect()}
              >
                <Text
                  style="subtitle1"
                  css={{
                    pb: '$4',
                    pt: '12px',
                  }}
                >
                  Logout
                </Text>
                <Box css={{ color: '$gray10' }}>
                  <FontAwesomeIcon
                    icon={faRightFromBracket}
                    width={16}
                    height={16}
                  />
                </Box>
              </Flex>
            </RadixDialog.Close>
          </Flex>
        ) : (
          <Flex
            direction="column"
            justify="between"
            css={{
              height: '100%',
              pb: '$5',
              px: '$4',
              gap: '$4',
            }}
          >
            <Flex direction="column">
              {/*<Link href="/" legacyBehavior>
                <Text
                  style="subtitle1"
                  css={{
                    borderBottom: '1px solid $gray4',
                    cursor: 'pointer',
                    pb: '$4',
                    pt: '24px',
                    width: '100%',
                  }}
                >
                  Explore
                </Text>
              </Link>*/}
              <RadixDialog.Close>
                <Link href={`/portfolio/${address || ''}`} legacyBehavior>
                <Flex
                    className="border-b border-primary"
                    direction="column"
                    css={{
                      cursor: 'pointer',
                      pb: '$4',
                      pt: '12px',
                      gap: '$1',
                    }}
                  >
                  <Text
                    style="subtitle1"
                  >
                    Portfolio
                  </Text>
                  </Flex>
                </Link>
              </RadixDialog.Close>
              <Link href="/blog" legacyBehavior>
                <RadixDialog.Close>
                  <Flex
                    className="border-b border-primary"
                    direction="column"
                    css={{
                      cursor: 'pointer',
                      pb: '$4',
                      pt: '12px',
                      gap: '$1',
                    }}
                  >
                    <Text style="subtitle1">Blogs</Text>
                    <Text style="body3" color="subtle"></Text>
                  </Flex>
                </RadixDialog.Close>
              </Link>
              <Link href="/dragons" legacyBehavior>
                <RadixDialog.Close>
                  <Flex
                    className="border-b border-primary"
                    direction="column"
                    css={{
                      cursor: 'pointer',
                      pb: '$4',
                      pt: '12px',
                      gap: '$1',
                    }}
                  >
                    <Text style="subtitle1">Dragons</Text>
                    <Text style="body3" color="subtle">
                      Mint your DiJT Dragons
                    </Text>
                  </Flex>
                </RadixDialog.Close>
              </Link>
              <Link href="https://app.mtopswap.com" target="_blank" rel="noopener noreferrer" legacyBehavior>
              <RadixDialog.Close>
                <Flex
                  className="border-b border-primary"
                  direction="column"
                  css={{
                    cursor: 'pointer',
                    pb: '$4',
                    pt: '12px',
                    gap: '$1',
                  }}
                >
                  <Text style="subtitle1">Swap</Text>
                  <Text style="body3" color="subtle">
                    Swap your tokens effortlessly using MtopSwap
                  </Text>
                </Flex>
              </RadixDialog.Close>
            </Link>
            </Flex>
            <Box>
              <RadixDialog.Close>
                <ConnectWalletButton />
              </RadixDialog.Close>
            </Box>
          </Flex>
        )}
        <Flex
          css={{
            pt: '2px',
            pb: '$5',
            px: '$4',
            gap: '$4',
            width: '100%',
          }}
        >
          <a href="https://twitter.com/dijtNFT" target="_blank">
            <Button
              css={{ justifyContent: 'center', width: '44px', height: '44px' }}
              type="button"
              size="small"
              color="gray3"
            >
              <FontAwesomeIcon icon={faXTwitter} />
            </Button>
          </a>
        </Flex>
      </Flex>
    </FullscreenModal>
  )
}

export default HamburgerMenu
