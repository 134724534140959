import { ChainContext } from 'context/ChainContextProvider';
import { useState, useEffect, useContext } from 'react';

type GasPriceInfo = {
  gasPrice: string;
};

const useGasPrices = () => {
  const [gasPriceInfo, setGasPriceInfo] = useState<GasPriceInfo | null>(null);
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState('');

  const { chain } = useContext(ChainContext); // Assuming chain object has an 'id' property

  useEffect(() => {
    const fetchGasPrice = async () => {
      if (!chain) {
        setError('No chain selected');
        return;
      }

      setLoading(true);
      setError('');

      try {
        console.log(`GETTING GAS PRICE FOR ${chain.id}`)
        const response = await fetch(`/api/gas/getGasPrice?chainId=${chain.id}`,{next:{revalidate:300}});
        if (!response.ok) {
          throw new Error(`Error: ${response.statusText}`);
        }
        const data = await response.json();
        setGasPriceInfo({ gasPrice: Number(data.gasPrice).toFixed(2) });
      } catch (err) {
        if (err instanceof Error) {
          setError(err.message);
        } else {
          setError('An unknown error occurred');
        }
      } finally {
        setLoading(false);
      }
    };

    fetchGasPrice();
  }, [chain]); // Dependency array includes 'chain' to refetch when the chain changes

  return { gasPriceInfo, loading, error };
};

export default useGasPrices;
