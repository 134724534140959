import { FC } from 'react'
import { Text, Box, Flex, Button } from '../primitives'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import {
  faXTwitter,
  faDiscord,
  faTelegram,
} from '@fortawesome/free-brands-svg-icons'
import Link from 'next/link'
import Image from 'next/image'

type SectionTitleProps = {
  title: string
}

const SectionTitle: FC<SectionTitleProps> = ({ title }) => (
  <Text className="font-semibold text-black dark:text-white" css={{ mb: 8 }}>
    {title}
  </Text>
)

type SectionLinkProps = {
  name: string
  href: string
}

const SectionLink: FC<SectionLinkProps> = ({ name, href }) => (
  <Link href={href}>
    <span className="text-jacarta-300 font-small text-sm mt-4 cursor-pointer">
      {name}
    </span>
  </Link>
)
const developerSectionLinks = [
  {
    name: 'Coming soon',
    href: '/',
  },
  /*{
    name: 'API Reference',
    href: 'https://docs.reservoir.tools/reference/overview',
  },
  {
    name: 'Github',
    href: 'https://github.com/reservoirprotocol',
  },*/
]

const startUps = [{ name: 'DiJT Incubation', href: '/incubator' }]

const companySectionLinks = [
  {
    name: 'Terms of Use',
    href: '/terms',
  },
  {
    name: 'Privacy Policy',
    href: '/privacy',
  },
]

export const Footer = () => {
  return (
    <Flex
      justify="between"
      css={{
        borderTop: '1px solid $gray7',
        borderStyle: 'solid',
        p: '$5',
        '@lg': {
          p: '$6',
        },
        flexDirection: 'column',
        alignItems: 'flex-start',
        gap: 36,
        '@bp600': {
          flexDirection: 'row',
          gap: 0,
        },
      }}
      className="text-black dark:text-white pb-10 px-10"
    >
      <Flex
        css={{ gap: 80, '@bp600': { gap: 136 } }}
        className="xs:flex-col md:flex-row"
      >
        <Flex align="center" className="flex-col">
          <Link href={`/`}>
            <Box className="w-28 cursor-pointer">
              <Image
                className="hidden dark:block"
                src="/dijt_light.png"
                width={112}
                height={36}
                alt="DiJT"
              />
              <Image
                className="dark:hidden"
                src="/dijt_dark.png"
                width={112}
                height={36}
                alt="DiJT"
              />
            </Box>
          </Link>
          <p className="dark:text-jacarta-300 mb-8 mt-4 text-center">
            Create, sell and collect truly rare digital artworks.
            <br />
            Powered by blockchain technology.
          </p>
        </Flex>
        <Flex direction="column">
          <SectionTitle title="Developers" />
          {developerSectionLinks.map((props) => (
            <SectionLink key={props.name} {...props} />
          ))}
        </Flex>
        <Flex direction="column">
          <SectionTitle title="Starting up?" />
          {startUps.map((props) => (
            <SectionLink key={props.name} {...props} />
          ))}
        </Flex>
        <Flex direction="column">
          <SectionTitle title="Company" />
          {companySectionLinks.map((props) => (
            <SectionLink key={props.name} {...props} />
          ))}
        </Flex>
      </Flex>
      <Flex
        direction="column"
        css={{ alignItems: 'flex-start', '@bp600': { alignItems: 'flex-end' } }}
      >
        <SectionTitle title="Join DiJT Community" />
        <div className="flex flex-row">
          <Flex css={{ gap: '$4', mt: 16 }}>
            <a
              target="_blank"
              rel="noopener noreferrer"
              href="https://discord.gg/HuTQEBbTxP"
            >
              <Button size="small" color="gray3">
                <FontAwesomeIcon
                  icon={faDiscord}
                  style={{ fontSize: '24px' }}
                />
              </Button>
            </a>
          </Flex>
          <Flex css={{ gap: '$4', mt: 16 }}>
            <a
              target="_blank"
              rel="noopener noreferrer"
              href="https://twitter.com/dijtnft"
            >
              <Button size="small" color="gray3">
                <FontAwesomeIcon
                  icon={faXTwitter}
                  style={{ fontSize: '24px' }}
                />
              </Button>
            </a>
          </Flex>
          <Flex css={{ gap: '$4', mt: 16 }}>
            <a
              target="_blank"
              rel="noopener noreferrer"
              href="https://t.me/+GtIxHXBPuYFhOGRl"
            >
              <Button size="small" color="gray3">
                <FontAwesomeIcon
                  icon={faTelegram}
                  style={{ fontSize: '24px' }}
                />
              </Button>
            </a>
          </Flex>
        </div>
      </Flex>
    </Flex>
  )
}
